import axios from 'axios';

//const BASE_URL = "https://stagingapi.theacademix.com/api/v1";
const BASE_URL = process.env.REACT_APP_API_URL;

const Header_1= {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "timeZone": new Date().getTimezoneOffset()*60*1000,
  Accept: "application/json",
  "Authorization": '',
};





class RestApi {

    getStudent(){
        return axios.get(BASE_URL);
    }


    
    loginAction= async (userInfo) => {
        const response = await axios.post(
            BASE_URL + '/student/webLogin',
            userInfo,
          {
            headers: Header_1
          }
        );
       return response;
      };

    loginAction(userInfo){
        return axios.post(BASE_URL + '/student/webLogin' , userInfo);
    }


    getStudentProfile= async (tokenId) => {
      Header_1.Authorization=tokenId;
          const response = await axios.get(
            BASE_URL + '/student/get-student',
          {
            headers: Header_1
          }
        );
        return response;
    };

    getStudentInstitude= async (tokenId) => {
      Header_1.Authorization=tokenId;
      const response = await axios.get(
        BASE_URL + '/student/getClassWiseInstitute',
      {
        headers: Header_1
      }
    );
    return response;
  };

  


  getClassListByInstitudeId= async (tokenId,pData) => {
    Header_1.Authorization=tokenId;
    const response = await axios.post(
      BASE_URL + '/student/getJoinClassByInstituteId',
      pData,
    {
      headers: Header_1
    }
  );
    
  return response;
};

addEditWatchPercentLecVideo= async (tokenId,pData) => {
  Header_1.Authorization=tokenId;
  const response = await axios.post(
    BASE_URL + '/student/addEditWatchPercentLecVideo',
    pData,
  {
    headers: Header_1
  }
);
  
return response;
};

addEditWatchPercentLecVideo= async (tokenId,pData) => {
  Header_1.Authorization=tokenId;
  const response = await axios.post(
    BASE_URL + '/student/addEditWatchPercentLecVideo',
    pData,
  {
    headers: Header_1
  }
);
  
return response;
};

addUpdateReadMaterial= async (tokenId,pData) => {
      Header_1.Authorization=tokenId;
      const response = await axios.post(
        BASE_URL + '/student/addUpdateReadMaterial',
        pData,
      {
        headers: Header_1
      }
    );
    return response;
    };


    getNoticeData= async (tokenId,pData) => {
      Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/common/getAllNotice',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        };

      getNoticeOneData= async (tokenId,pData) => {
        Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/getNoticeById',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        }; 

      getOneClassData= async (tokenId,pData) => {
        Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/getClassById',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        };


        getSubjectData= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/get-class-subject',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        };

        subjectsForAttendance= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/subjectsForAttendance',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        };

        getAttendanceListData= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/getAllAttendance',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        };

        addAttendance= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/addAttendance',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        };

        
        getLiveClasses= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/getAllVideoClassV1',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        };

        getBBBRecording= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/common/getRecording',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        };

        // joinBigBlueClass= async (tokenId,liveClassId) => {
        //   Header_1.Authorization=tokenId;
        //   const response = await axios.get(
        //     BASE_URL + `/common/joinBigBlueButtonClass?id=${liveClassId}`,
        //   {
        //     headers: Header_1
        //   }
        // );
        // return response;
        // };

        getAllLecture= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/getAllLecture',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        };
        
        getWatchLaterLecture= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/getWatchLaterLecture',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        };

        
        getLectureOneData= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/getLectureSectionVideo',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        }; 

        addWatchLaterLecture= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/addWatchLaterLecture',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        }; 

        removeWatchLaterLecture= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/removeWatchLaterLecture',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        }; 


        

         getMaterialData= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/get-study-matarial',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        };       

        getAllAssignment= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/get-particular-class-home-work',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        };


        assignmentOneData= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/get-particular-home-work',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        }; 

        getSubmitListByAssignmentId= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/getParticularSubmitHomeWorkById',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        }; 



        


        presignData= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/common/presignUrlToUploadFile',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        }; 

        presignUrlStatusUpdate= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/common/presignUrlStatusUpdate',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        }; 

        submitAssignment= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/add-home-work',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        }; 

        getCalendarList= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/common/getClassCalendarListV1',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        }; 

        joinBigBlueClass= async (tokenId,itemId) => {
          Header_1.Authorization=tokenId;
          const response = await axios.get(
            BASE_URL + `/common/joinBigBlueButtonClass?id=${itemId}`,
          {
            headers: Header_1
          }
        );
        return response;
        }; 

        
        getAllQuizList= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/getAllQuizList',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        }; 

         getClassDetailById= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/getClassById',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        }; 

        getClassLogByStudent= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/getClassLogByStudent',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        }; 

        getAllStudentByClassId= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/student/getAllStudentByClassId',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
        }; 

        

        submitProfile= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.put(
            BASE_URL + '/student/edit-student',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
      };
        

    changePassword= async (tokenId,pData) => {
      Header_1.Authorization=tokenId;
        const response = await axios.post(
          BASE_URL + '/student/changePassword',
          pData,
        {
          headers: Header_1
        }
      );
      return response;
    };

    getAllQuizList= async (tokenId,pData) => {
      Header_1.Authorization=tokenId;
        const response = await axios.post(
          BASE_URL + '/student/getAllQuizList',
          pData,
        {
          headers: Header_1
        }
      );
      return response;
    };

    getAllQuestionByQuizId= async (tokenId,pData) => {
      Header_1.Authorization=tokenId;
        const response = await axios.post(
          BASE_URL + '/student/getAllQuestionByQuizId',
          pData,
        {
          headers: Header_1
        }
      );
      return response;
    };


    getAllLectureTestQuestion= async (tokenId,pData) => {
      Header_1.Authorization=tokenId;
        const response = await axios.post(
          BASE_URL + '/student/getAllLectureTestQuestion',
          pData,
        {
          headers: Header_1
        }
      );
      return response;
    };

    addRepeatQuizList= async (tokenId,pData) => {
      Header_1.Authorization=tokenId;
        const response = await axios.post(
          BASE_URL + '/student/addRepeatQuizList',
          pData,
        {
          headers: Header_1
        }
      );
      return response;
    };

    addQuizRepeatLecture= async (tokenId,pData) => {
      Header_1.Authorization=tokenId;
        const response = await axios.post(
          BASE_URL + '/student/addQuizRepeatLecture',
          pData,
        {
          headers: Header_1
        }
      );
      return response;
    };


    addRemoveQuestionLaterMark= async (tokenId,pData) => {
      Header_1.Authorization=tokenId;
        const response = await axios.post(
          BASE_URL + '/student/addRemoveQuestionLaterMark',
          pData,
        {
          headers: Header_1
        }
      );
      return response;
    };

    getStudentNotification= async (tokenId,pData) => {
      Header_1.Authorization=tokenId;
      const response = await axios.post(
        BASE_URL + '/student/getAllNotificationByStudent',
        pData,
      {
        headers: Header_1
      }
    );
    return response;
    };

    submitQuestionAnswer= async (tokenId,pData) => {
      Header_1.Authorization=tokenId;
            const response = await axios.post(
              BASE_URL + '/student/submitQuestionAnswer',
              pData,
            {
              headers: Header_1
            }
          );
          return response;
        };

        submitLectureQuesAnswer= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
                const response = await axios.post(
                  BASE_URL + '/student/submitLectureQuesAnswer',
                  pData,
                {
                  headers: Header_1
                }
              );
              return response;
            };
    

        submitRepeatQuiz= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
            const response = await axios.post(
              BASE_URL + '/student/submitRepeatQuiz',
              pData,
            {
              headers: Header_1
            }
          );
          return response;
        };

        submitLectureQuizRepeat= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
            const response = await axios.post(
              BASE_URL + '/student/submitLectureQuizRepeat',
              pData,
            {
              headers: Header_1
            }
          );
          return response;
        };

        

        languageListGet= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
            const response = await axios.post(
              BASE_URL + '/common/getAllLanguages',
              pData,
            {
              headers: Header_1
            }
          );
          return response;
        };


        getGraphReport= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
            const response = await axios.post(
              BASE_URL + '/student/getGraphReport',
              pData,
            {
              headers: Header_1
            }
          );
          return response;
        };

        getGraphReportLecture= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
            const response = await axios.post(
              BASE_URL + '/student/getLecQuizResultGraph',
              pData,
            {
              headers: Header_1
            }
          );
          return response;
        };

        getResultSubjectReport= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
            const response = await axios.post(
              BASE_URL + '/student/getResultSubjectReport',
              pData,
            {
              headers: Header_1
            }
          );
          return response;
        };


        getResultQuestionReport= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
            const response = await axios.post(
              BASE_URL + '/student/getResultQuestionReport',
              pData,
            {
              headers: Header_1
            }
          );
          return response;
        };

        getResultTopicReport= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
            const response = await axios.post(
              BASE_URL + '/student/getResultTopicReport',
              pData,
            {
              headers: Header_1
            }
          );
          return response;
        };

        

        getAllResultQuiz= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
            const response = await axios.post(
              BASE_URL + '/student/getAllResultQuiz',
              pData,
            {
              headers: Header_1
            }
          );
          return response;
        };



        getResultCompare= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
            const response = await axios.post(
              BASE_URL + '/student/getResultCompare',
              pData,
            {
              headers: Header_1
            }
          );
          return response;
        };
        
        
        getQuizAttemptHistory= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
            const response = await axios.post(
              BASE_URL + '/student/getQuizAttemptHistory',
              pData,
            {
              headers: Header_1
            }
          );
          return response;
        };


        
        getClassGroupByIdForMessage= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
            const response = await axios.post(
              BASE_URL + '/common/getClassGroupByIdForMessage',
              pData,
            {
              headers: Header_1
            }
          );
          return response;
        };
        

        getUserProfile= async (tokenId,pData) => {
          Header_1.Authorization=tokenId;
          const response = await axios.post(
            BASE_URL + '/common/getMessageUserByUserId',
            pData,
          {
            headers: Header_1
          }
        );
        return response;
      };
      

      logout= async (tokenId) => {
        Header_1.Authorization=tokenId;
        const response = await axios.post(
          BASE_URL + '/account/logout',
          {},
        {
          headers: Header_1
        }
      );
      return response;
    };
    
    

    getStudentById(employeeId){
        return axios.get(EMPLOYEE_API_BASE_URL + '/' + employeeId);
    }
    updateEmployee(employee, employeeId){
        return axios.put(EMPLOYEE_API_BASE_URL + '/' + employeeId, employee);
    }

    deleteEmployee(employeeId){
        return axios.delete(EMPLOYEE_API_BASE_URL + '/' + employeeId);
    }
}

export default new RestApi()